import React, { Component } from 'react'
import {Form, Button, Row, Col, Card, Modal} from 'react-bootstrap'
import axios from "axios";
import Loader from "../shared/Loader";
import helpers from "../shared/layouts/helpers";
import Swal from "sweetalert2";
import AuthService from '../services/auth-service';
import authService from "../services/auth-service";

class Register extends Component {
    constructor(props) {
        super(props);
        if (!helpers.getUser()) {
            document.body.style.background = "#243449";
        }
        document.title = "Partner Portal | " + helpers.getSystem()

        this.state = {
            country: this.props.match.params.country.toUpperCase(),
            guid: this.props.match.params.guid,
            step: 1,
            type: 0,
            username: "",
            password: "",
            rememberMe: "",
            loading: false,
            error: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.step === 1) {
            return this.setState({step: 2});
        }

        this.setState({loading: true});

        axios.post(helpers.getAPI(this.state.country) + '/partnerportal/api/register?guid='+this.state.guid, JSON.stringify(this.state)).then(res => {
            if (res.data.success) {
                Swal.fire(
                    'Account created!',
                    "",
                    'success'
                ).then(() => {
                    AuthService.login(this.state.customer_username, this.state.customer_password, this.state.country).then(
                        () => {
                            window.location = "/";
                        },
                        error => {
                            Swal.fire(
                                'Invalid credentials!',
                                'Please check your username and password.',
                                'error',
                            );

                            this.setState({
                                loading: false,
                            });
                        }
                    );
                });
            } else {
                Swal.fire(
                    'This account already exists..',
                    "The account seems to already exist in our database, please contact your suppler or Mentech for help.",
                    'warning'
                );
            }
            this.setState({
                loading: false,
            });
        }).catch(error => {
            Swal.fire(
                'Something went wrong..',
                helpers.parseError(error),
                'error'
            );
        })
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    componentDidMount() {
            axios.get(helpers.getAPI(this.state.country) + "/partnerportal/api/register/details/"+this.state.guid).then(response => {
                this.setState({...response.data});
            }).catch(error => {

            });
    }

    render() {
        return (
            <div className="authentication-wrapper authentication-1 px-4">
                {this.state.loading && <Loader/>}
                {helpers.getUser() && <div className="authentication-inner py-5">
                    <Card>
                        <Card.Body>
                            <h6>Registration page is only available, if you aren't logged in.</h6>
                            <br/>
                            <Button onClick={() => authService.logout()} variant={"primary"}>Log out</Button>
                        </Card.Body>
                    </Card>
                </div>}

                {!helpers.getUser() && <div className="authentication-inner py-5">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="ui-w-60">
                            <div className="w-250 position-relative text-center" style={{ paddingBottom: '40%' }}>
                                <img style={{width: '250px', marginLeft: '-90px'}} src={this.state.logo ? this.state.logo : (process.env.PUBLIC_URL + '/img/mentech-hvidblaa.svg')}/>
                                <small as={"h6"} className={"text-white mt-2"}><strong>[PARTNER]</strong></small>

                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                        <div className="col-md-4 my-5">
                            <Form onSubmit={this.handleSubmit}>
                                <Card>
                                    <Card.Body>
                                        {this.state.loading && <Loader/>}

                                        {this.state.step === 1 && <div>
                                            <Card.Title className={"mb-3"} as={"h6"}>1. Please enter your company details</Card.Title>
                                            <Form.Group>
                                                <Form.Label>Type</Form.Label>
                                                <Form.Control as={"select"} name={"type"} value={this.state.type} onChange={this.handleInputChange} required>
                                                    <option value={"0"}>Business</option>
                                                    <option value={"1"}>Government</option>
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control type="text" name="name" value={this.state.name} onChange={this.handleInputChange} required/>
                                            </Form.Group>

                                            {this.state.type === "1" && <Form.Group>
                                                <Form.Label>EAN</Form.Label>
                                                <Form.Control type="text" name="ean" value={this.state.ean} onChange={this.handleInputChange} required/>
                                            </Form.Group>}
                                            <Form.Group>
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control type="text" name="address" value={this.state.address} onChange={this.handleInputChange} required/>
                                            </Form.Group>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Zip postal</Form.Label>
                                                        <Form.Control type="text" name="zipPostal" value={this.state.zipPostal} onChange={this.handleInputChange} required/>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Group>
                                                        <Form.Label>City</Form.Label>
                                                        <Form.Control type="text" name="city" value={this.state.city} onChange={this.handleInputChange} required/>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control type="email" name="email" value={this.state.email} onChange={this.handleInputChange} required/>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Phone</Form.Label>
                                                        <Form.Control type="text" name="phone" value={this.state.phone} onChange={this.handleInputChange} required/>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>}

                                        {this.state.step === 2 && <div>
                                            <Card.Title className={"mb-3"} as={"h6"}>2. Please enter your details</Card.Title>

                                            <Form.Group>
                                                <Form.Label>Full name</Form.Label>
                                                <Form.Control type="text" name="customer_fullName" value={this.state.customer_fullName} onChange={this.handleInputChange} required/>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control type="text" name="customer_address" value={this.state.customer_address} onChange={this.handleInputChange} required/>
                                            </Form.Group>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Zip postal</Form.Label>
                                                        <Form.Control type="text" name="customer_zipPostal" value={this.state.customer_zipPostal} onChange={this.handleInputChange} required/>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Group>
                                                        <Form.Label>City</Form.Label>
                                                        <Form.Control type="text" name="customer_city" value={this.state.customer_city} onChange={this.handleInputChange} required/>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control type="email" name="customer_email" value={this.state.customer_email} onChange={this.handleInputChange} required/>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Phone</Form.Label>
                                                        <Form.Control type="text" name="customer_phone" value={this.state.customer_phone} onChange={this.handleInputChange} required/>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Card bg={"dark"} className={"mb-4 mt-2"}>
                                                        <Card.Body className={"text-white"}>
                                                            <h6><i className={"fa fa-info-circle"}></i> Create your account</h6>
                                                            <small className={"text-muted"}>Enter your desired username and password that you will use to log in to the portal.</small>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <Form.Label>Username</Form.Label>
                                                        <Form.Control type="text" name="customer_username" value={this.state.customer_username} onChange={this.handleInputChange} required/>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>Password</Form.Label>
                                                        <Form.Control type="password" name="customer_password" value={this.state.customer_password} onChange={this.handleInputChange} required/>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>}
                                    </Card.Body>
                                    <Modal.Footer>
                                        {this.state.step === 2 && <Button variant="default" onClick={() => this.setState({step: 1})}>
                                            Back
                                        </Button>}
                                        <Button type="submit" variant="primary" disabled={this.state.loading}>
                                            {this.state.step === 1 ? 'Next' : 'Create'}
                                        </Button>
                                    </Modal.Footer>
                                </Card>
                            </Form>
                        </div>
                    </div>

                    {this.state.logo && <div className="d-flex justify-content-center align-items-center mt-5">
                        <div className="ui-w-250">
                            <div className="w-250 position-relative text-center" style={{ paddingBottom: '40%' }}>
                                <small as={"h6"} style={{ letterSpacing: '0.15em' }} className={"text-white mt-4"}><strong>POWERED BY</strong></small>

                                <br/>
                                <img style={{width: '200px'}} src={process.env.PUBLIC_URL + '/img/mentech-hvidblaa.svg'}/>
                            </div>
                        </div>
                    </div>}

                </div>}
            </div>
        )
    }
}

export default Register
