import React from 'react'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import Loader from './shared/Loader'

// Layouts
import Layout2 from "./shared/layouts/Layout2";
import helpers from "./shared/layouts/helpers";

// Lazy load component
const lazy = (cb) => loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> });

// ---
// Default application layout

export const DefaultLayout = Layout2;

// ---
// Document title template

export const titleTemplate = '%s - ' + helpers.getSystem();

// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object

export const defaultRoute = '/dashboard';
export const routes = [
  {
    path: '/dashboard',
    component: lazy(() => import('./components/Dashboard'))
  }, {
    path: '/login',
    component: lazy(() => import('./components/Login'))
  },  {
    path: '/register/:guid/:country',
    component: lazy(() => import('./components/Register'))
  }, {
    path: '/verifyLogin/:token/:country',
    component: lazy(() => import('./components/VerifyLogin'))
  }, {
    path: '/logout',
    component: lazy(() => import('./components/Logout'))
  }, {
    path: '/orders/create/:id?',
    roles: ['ROLE_GOVERNMENT', 'ROLE_BUSINESS'],
    component: lazy(() => import('./components/Order/CreateOrder'))
  }, {
    path: '/orders/active',
    roles: ['ROLE_GOVERNMENT', 'ROLE_BUSINESS'],
    component: lazy(() => import('./components/Order/ActiveOrders'))
  }, {
    path: '/orders/completed',
    roles: ['ROLE_GOVERNMENT', 'ROLE_BUSINESS'],
    component: lazy(() => import('./components/Order/CompletedOrders'))
  },  {
    path: '/orders/purchaseOrders',
    roles: ['ROLE_GOVERNMENT', 'ROLE_BUSINESS'],
    component: lazy(() => import('./components/Order/PurchaseOrders'))
  }, {
    path: '/orders/view/:id',
    roles: ['ROLE_GOVERNMENT', 'ROLE_BUSINESS'],
    component: lazy(() => import('./components/Order/ViewOrder'))
  }, {
    path: '/cases/view/:id/:tab?',
    component: lazy(() => import('./components/Project/ViewProject'))
  }, {
    path: '/orders/cases/all',
    component: lazy(() => import('./components/Project/AllProjects'))
  }, {
    path: '/orders/cases/create',
    roles: ["ROLE_INSURANCE"],
    component: lazy(() => import('./components/Project/CreateProject'))
  }, {
    path: '/departments/all',
    roles: ["ROLE_CUSTOMER_SUPER_ADMIN_USER", "ROLE_CUSTOMER_READ_ONLY"],
    component: lazy(() => import('./components/Department/Departments'))
  }, {
    path: '/departments/view/:id',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER', 'ROLE_CUSTOMER_READ_ONLY'],
    component: lazy(() => import('./components/Department/ViewDepartment'))
  }, {
    path: '/users/all/:department?',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER', 'ROLE_CUSTOMER_DEPARTMENT_SUPER_USER', 'ROLE_CUSTOMER_DEPARTMENT_USER', 'ROLE_CUSTOMER_READ_ONLY'],
    component: lazy(() => import('./components/Customer/Users'))
  }, {
    path: '/users/view/:id',
    component: lazy(() => import('./components/Customer/ViewUser'))
  }, {
    path: '/info/prices/:tab?',
    component: lazy(() => import('./components/Info/Prices'))
  }, {
    path: '/info/agreements',
    component: lazy(() => import('./components/Info/Agreements'))
  }, {
    path: '/info/files',
    component: lazy(() => import('./components/Info/Files'))
  }, {
    path: '/contact',
    component: lazy(() => import('./components/Info/Contact'))
  }, {
    path: '/administration/budget',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER', 'ROLE_CUSTOMER_READ_ONLY'],
    component: lazy(() => import('./components/Administration/Budget'))
  }, {
    path: '/administration/settings',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/Administration/Settings'))
  }, {
    path: '/tools/all',
    component: lazy(() => import('./components/Tools/Tools'))
  },  {
    path: '/damage/prevention',
    addon: '71',
    component: lazy(() => import('./components/Tools/DamagePrevention'))
  }, {
    path: '/administration/informationFlow',
    roles: ['ROLE_INSURANCE'],
    component: lazy(() => import('./components/Administration/InformationFlow'))
  }, {
    path: '/reports/insurance',
    roles: ['ROLE_INSURANCE'],
    component: lazy(() => import('./components/Report/InsuranceReport'))
  }, {
    path: '/reports/business',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/Report/BusinessReport'))
  },  {
    path: '/reports/seedstock',
    addon: '202',
    component: lazy(() => import('./components/Report/SeedStockReport'))
  },  {
    path: '/reports/projectstock',
    addon: '202',
    component: lazy(() => import('./components/Report/ProjectStockReport'))
  }, {
    path: '/search/:query',
    component: lazy(() => import('./components/Search'))
  }, {
    path: '/webshop/leasing',
    addon: '19',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/Webshop/Leasing'))
  }, {
    path: '/webshop/carepacks/show/:id',
    component: lazy(() => import('./components/Webshop/ShowCarepack'))
  },  {
    path: '/webshop/carepacks',
    component: lazy(() => import('./components/Webshop/Carepacks'))
  }, {
    path: '/webshop/carepacks/create',
    component: lazy(() => import('./components/Webshop/CreateCarepack'))
  }, {
    path: '/cms/customers',
    addon: '6',
    component: lazy(() => import('./components/CMS/Customers'))
  }, {
    path: '/cms/customers/view/:id',
    addon: '6',
    component: lazy(() => import('./components/CMS/ViewCustomer'))
  }, {
    path: '/inventory/devices/:department?',
    addon: '100',
    component: lazy(() => import('./components/MAM/Devices/AllDevices'))
  },{
    path: '/inventory/devices/show/:id',
    component: lazy(() => import('./components/MAM/Devices/ViewDevice'))
  }, {
    path: '/inventory/sim/cards/:department?',
    addon: '10',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER', 'ROLE_CUSTOMER_READ_ONLY'],
    component: lazy(() => import('./components/MAM/SimCards/AllSimCards'))
  }, {
    path: '/inventory/administration/models',
    addon: '100',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/MAM/Models/AllModels'))
  }, {
    path: '/users/resigning/:resigned',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER', 'ROLE_CUSTOMER_DEPARTMENT_USER', 'ROLE_CUSTOMER_READ_ONLY'],
    component: lazy(() => import('./components/Customer/UsersResigning'))
  }, {
    path: '/api/doc/:view?',
    component: lazy(() => import('./components/Tools/ApiDoc'))
  }, {
    path: '/device/shop/assortment/all',
    addon: '25',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/DeviceShop/Administration/Assortments'))
  }, {
    path: '/device/shop/return/option/all/:validated?',
    addon: '25',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/DeviceShop/Administration/ReturnOptions'))
  },  {
    path: '/device/shop/return/option/all/:validated?',
    addon: '24',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/DeviceShop/Administration/ReturnOptions'))
  }, {
    path: '/device/shop/return/option/show/:id',
    addon: '25',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/DeviceShop/Administration/EditReturnOption'))
  }, {
    path: '/device/shop/return/option/show/:id',
    addon: '24',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/DeviceShop/Administration/EditReturnOption'))
  }, {
    path: '/device/shop/assortment/edit/:id',
    addon: '25',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/DeviceShop/Administration/CreateEditAssortment'))
  }, {
    path: '/device/shop/assortment/create',
    addon: '25',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/DeviceShop/Administration/CreateEditAssortment'))
  }, {
    path: '/device/shop/product/all',
    addon: '25',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/DeviceShop/Administration/Products'))
  }, {
    path: '/device/shop/product/edit/:id',
    addon: '25',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/DeviceShop/Administration/CreateEditProduct'))
  }, {
    path: '/device/shop/product/create',
    addon: '25',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/DeviceShop/Administration/CreateEditProduct'))
  }, {
    path: '/device/shop/agreement/all',
    addon: '25',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/DeviceShop/Administration/Agreements'))
  }, {
    path: '/device/shop/agreement/edit/:id',
    addon: '25',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/DeviceShop/Administration/CreateEditAgreement'))
  }, {
    path: '/device/shop/agreement/create',
    addon: '25',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/DeviceShop/Administration/CreateEditAgreement'))
  }, {
    path: '/device/shop/accessory/all',
    addon: '25',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/DeviceShop/Administration/Accessories'))
  }, {
    path: '/device/shop/accessory/edit/:id',
    addon: '25',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/DeviceShop/Administration/CreateEditAccessory'))
  }, {
    path: '/device/shop/accessory/create',
    addon: '25',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER'],
    component: lazy(() => import('./components/DeviceShop/Administration/CreateEditAccessory'))
  }, {
    path: '/device/shop/orders',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER', "ROLE_CUSTOMER_READ_ONLY"],
    component: lazy(() => import('./components/DeviceShop/DeviceShopOrders'))
  }, {
    path: '/device/shop/orders/view/:id',
    roles: ['ROLE_CUSTOMER_SUPER_ADMIN_USER', "ROLE_CUSTOMER_READ_ONLY", 'ROLE_CUSTOMER_TOOL_USER'],
    component: lazy(() => import('./components/DeviceShop/DeviceShopOrder'))
  },
];
