import React, {useEffect} from 'react'
import AuthService from "../../services/auth-service";
import helpers from "../layouts/helpers";
import {Button, Card, Col} from "react-bootstrap";


function InternalError() {
    useEffect(() => {
        document.body.style.background = "linear-gradient(to right, #243449, #52768f)";
        return () => {
            // Reset the background when the component unmounts
            document.body.style.background = '';
        };
    }, []); // Empty dependency array to run only once after mounting


    return (
      <div className="container d-flex align-items-center ui-mh-100vh">
          <div className="row align-content-center align-items-start align-items-md-center w-100 py-5">
              <Col md={12}>
                  <Card>
                      <Card.Body className={"text-center"}>
                        <h1><i className={"lnr lnr-cloud-sync"}/> </h1>
                        <h4>Your session has expired</h4>
                        <p>Please renew your session to continue using the partner portal.</p>
                        <Button variant={"primary"} onClick={() => AuthService.logout()}>Login</Button>
                      </Card.Body>
                  </Card>
              </Col>

          </div>
      </div>

  )
}

export default InternalError
