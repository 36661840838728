import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import helpers from "./shared/layouts/helpers";

i18n
    .use(Backend)
    //.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: helpers.getUser() && helpers.getUser().language ? helpers.getUser().language : "en",
        fallbackLng: 'en',
        debug: false,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false
        },
    });

export default i18n;