import React, { Component } from 'react'
import {Form, Button, Row, Col, Card} from 'react-bootstrap'
import axios from "axios";
import Loader from "../shared/Loader";
import helpers from "../shared/layouts/helpers";
import Swal from "sweetalert2";
import AuthService from '../services/auth-service';

class VerifyLogin extends Component {
    constructor(props) {
        super(props);
        document.body.style.background = "#243449";
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.setUser({
            fullName : "",
            refreshToken: this.props.match.params.token,
            connectTo: this.props.match.params.country,
        }).then(() => {
            AuthService.refreshToken().then(() => {
                axios.post(helpers.getAPI(this.props.match.params.country) + '/partner/api/generate/new/refresh/key?refresh='+helpers.getUser().refreshToken+'&api='+helpers.getUser().accessToken, null).then(response => {
                    console.log(response.data);
                    this.setUser(response.data).then(() => {
                        window.location = "/";
                    })
                });
            }).catch(() => {
                this.setState({loading: false});
                helpers.setUser(null);
                Swal.fire(
                    "Invalid credentials!",
                    "We could not find an account matching your email. Please contact your IT department.",
                    'error',
                ).then(() => {
                    window.location = "/";
                })
            })
        })
    }

    setUser(data) {
        return new Promise(function(resolve, reject) {
            helpers.setUser(data);
            resolve(1)
        });
    }


    render() {
        return (
            <div className="authentication-wrapper authentication-1 px-4"  style={{background: "#243449"}}>
                {this.state.loading && <Loader/>}

                <div className="authentication-inner py-5">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="ui-w-250">
                            <div className="w-250 position-relative text-center" style={{ paddingBottom: '40%' }}>
                                <img className={"mb-2"} style={{width: '250px'}} src={this.state.managerLogo ? this.state.managerLogo : helpers.getLogo()}/>
                                <br/>
                                <small as={"h6"} className={"text-white mt-4"}><strong>[PARTNER]</strong></small>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default VerifyLogin;
